import * as React from 'react'
import {Link} from 'gatsby'


const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}


export default function NotFoundPage() {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>

      <p>
        <Link to='/'>Go home</Link>
      </p>
    </main>
  )
}
